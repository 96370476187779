/**
 * Internal dependencies
 */
import { display_error } from './bookings-lib';

jQuery(document).ready(function($) {

	if ( ! window.console ) {
		window.console = {
			log : function() {
			}
		};
	}

	let xhr = [];
	wc_bookings_booking_form.wc_booking_form = $( '.wc-bookings-booking-form' ).closest( 'form' );

	$( '.wc-bookings-booking-form' )
		.on( 'change', 'input, select:not("#wc-bookings-form-start-time, #wc-bookings-form-end-time")', function ( e ) {

			var name = $( this ).attr( 'name' );
			const booking_duration_type = wc_bookings_booking_form.get_booking_duration_type( $( this ) );
			const booking_duration_unit = wc_bookings_booking_form.get_booking_duration_unit( $( this ) );
			const $form = $( this ).closest( 'form' )

			// If it's the resource dropdown, we refresh the datepicker so that the
			// calendar availability reflects the potential differences, where it
			// may differ for different resources.
			if ( 'wc_bookings_field_resource' === name ) {
				// Clear selection as availability in another resource might not apply.
				wc_bookings_booking_form.wc_bookings_date_picker.clear_selection();
				// Re-init the picker.
				wc_bookings_booking_form.wc_bookings_date_picker.init();

				return;
			}

			// Don't do anything on date change for hour and minute durations.
			if ( 'wc_bookings_field_start_date_day' === name && ['hour', 'minute'].includes( booking_duration_unit ) ) {
				return;
			}

			// If start time is not set, don't do anything.
			if ( 'customer' === booking_duration_type && '0' === $form.find( '#wc-bookings-form-start-time' ).val() ) {
				return;
			}

			// If end time is not set, don't do anything.
			if ( 'customer' === booking_duration_type && '0' === $form.find( '#wc-bookings-form-end-time' ).val() ) {
				return;
			}

			let $fieldset = $( this ).closest( 'fieldset' );
			let $picker = $fieldset.find( '.picker:eq(0)' );
			if ( $picker.data( 'is_range_picker_enabled' ) ) {
				if ( 'wc_bookings_field_duration' !== name ) {
					return;
				}
			}

			let index = $form.index( this );
			let isEmptyCalendarSelection = !$form.find( "[name='wc_bookings_field_start_date_day']" ).val() &&
			                               !$form.find( '#wc_bookings_field_start_date' ).val();

			// Do not update if triggered by Product Addons and no date is selected.
			if ( jQuery( e.target ).hasClass( 'addon' ) && isEmptyCalendarSelection ) {
				return;
			}

			let required_fields = $form.find( 'input.required_for_calculation' );
			let filled = true;
			$.each( required_fields, function ( index, field ) {
				let value = $( field ).val();
				if ( !value ) {
					filled = false;
				}
			} );
			if ( !filled ) {
				$form.find( '.wc-bookings-booking-cost' ).hide();
				return;
			}

			$form.find( '.wc-bookings-booking-cost' ).block( {
				message: null,
				overlayCSS: {background: '#fff', backgroundSize: '16px 16px', opacity: 0.6}
			} ).show();
			xhr[index] = $.ajax( {
				type: 'POST',
				url: booking_form_params.ajax_url,
				data: {
					action: 'wc_bookings_calculate_costs',
					form: $form.serialize()
				},
				success: function ( code ) {
					if ( code.charAt( 0 ) !== '{' ) {
						// eslint-disable-next-line
						console.log( code );
						code = '{' + code.split( /\{(.+)?/ )[1];
					}

					let result = JSON.parse( code );

					if ( result.result === 'ERROR' ) {
						$form.find( '.wc-bookings-booking-cost' ).html( result.html );
						$form.find( '.wc-bookings-booking-cost' ).unblock();
						$form.find( '.wc-bookings-booking-cost' ).show();
						$form.find( '.single_add_to_cart_button' ).addClass( 'disabled' );
					} else if ( result.result === 'SUCCESS' ) {
						$form.find( '.wc-bookings-booking-cost' ).html( result.html );
						$form.find( '.wc-bookings-booking-cost' ).unblock();
						$form.find( '.single_add_to_cart_button' ).removeClass( 'disabled' );

						if ( booking_form_params.pao_active && 'true' !== booking_form_params.pao_pre_30 && typeof result.raw_price !== 'undefined' ) {
							$form.find( '.wc-bookings-booking-cost' ).attr( 'data-raw-price', result.raw_price );
							$( 'form.cart' ).trigger( 'woocommerce-product-addons-update' );
						}
					} else {
						$form.find( '.wc-bookings-booking-cost' ).hide();
						$form.find( '.single_add_to_cart_button' ).addClass( 'disabled' );
						// eslint-disable-next-line
						console.log( code );
					}

					$( document.body ).trigger( 'wc_booking_form_changed', [$form] );

					// Hide error notices.
					$( '.woocommerce-error.wc-bookings-notice' ).slideUp();
				},
				error: function ( jqXHR, exception ) {
					if ( 'abort' === exception ) {
						return; // Assuming the date is changed very quickly.
					}

					display_error();

					$form.find( '.wc-bookings-booking-cost' ).hide();
					$form.find( '.single_add_to_cart_button' ).addClass( 'disabled' );

					if ( booking_form_params.pao_active && 'true' !== booking_form_params.pao_pre_30 ) {
						$( 'form.cart' ).trigger( 'woocommerce-product-addons-update' );
					}
				},
				dataType: "html"
			} );
		} )
		.each( function () {
			let button = $( this ).closest( 'form' ).find( '.single_add_to_cart_button' );

			button.addClass( 'disabled' );
		} );

	$( '.single_add_to_cart_button' ).on( 'click', function ( event ) {
		if ( $( this ).hasClass( 'disabled' ) ) {
			// eslint-disable-next-line
			alert( booking_form_params.i18n_choose_options );
			event.preventDefault();
			return false;
		}
	} )

	// Prevent custom booking creation if required slots are not selected.
	// Checking if price is visible, if not, slots are not selected.
	$( '.add_custom_booking' ).on( 'click', function( event ) {
		if ( ! $( '.wc-bookings-booking-cost' ).is( ':visible' ) || $( '.wc-bookings-booking-cost .booking-error' ).is( ':visible' ) ) {
			// eslint-disable-next-line
			alert( booking_form_params.i18n_choose_options );
			event.preventDefault();
			return false;
		}
	} );

	if ( 'true' === booking_form_params.pao_pre_30 ) {
		$( '.wc-bookings-booking-form' ).parent()
			.on( 'updated_addons', function() {
				$( '.wc-bookings-booking-form' ).find( 'input' ).first().trigger( 'change' );
			} );
	}

	$('.wc-bookings-booking-form, .wc-bookings-booking-form-button').show().prop( 'disabled', false );

});
